import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import './BulkTalentModal.scss';

import Modal from '../General/Modal';
import BulkTalentModalSidebar from './BulkTalentModalSidebar';
import BulkTalentModalBody from './BulkTalentModalBody';
import BulkTalentModalFooter from './BulkTalentModalFooter';

import { getBrandRemaining } from '../../APIClient/brands';
import { getTalent as getTalentAPI } from '../../APIClient/talent';

import { closeBulkTalentModal, openArtistModal, openChatOverlay, openRequestModal } from '../../Actions/UIActions';
import { createSamplesRequest, setCustomCommissionRate } from '../../Actions/AnalyticsActions';
import { updateBrandSettings } from '../../Actions/BrandActions';
import { getTalent as syncTalent } from '../../Actions/TalentActions';

import { sendChatMessage } from '../../APIClient/chats';
import { getBulkTalentModalStartingParams, isAdminControlMode } from '../../Helpers/ui_helpers';
import { replaceTemplateVariables } from '../../Helpers/formatting';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';
import { getBrandListById, getBrand, getBrandId, isSMSAffiliatePartnerBrand /*, getLookbookById*/ } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import { getTalent, isPromoterForOutreachPurposes } from '../../Helpers/talent_helpers';

const BulkTalentModal = props => {
  const { user, ui } = props;
  const close = () =>
    isProcessingSend ? cogoToast.warn(`Please do not leave this panel until the actions have completed.`) : props.closeBulkTalentModal();

  /*
    Store messaging and data for sending to talent
  */
  const [curMessage, setCurMessage] = useState('');
  const [curRate, setCurRate] = useState('');
  const [fetchingRemaining, setFetchingRemaining] = useState(true);
  const [brandRemaining, setBrandRemaining] = useState({});
  useEffect(() => {
    getBrandRemaining(user)
      .then(resp => setBrandRemaining(resp))
      .finally(() => setFetchingRemaining(false));
  }, []);
  // const updateRemainingGifting = newRemaining => setBrandRemaining({ ...brandRemaining, gifting_remaining: _.max([newRemaining, 0]) });

  /*
    Allow file uploads
  */
  const [filesToUpload, setFilesToUpload] = useState([]);

  /*
    Initialize Talent Based on Passed through Params
  */
  const talent = getTalent(props.talent);
  const params = getBulkTalentModalStartingParams(ui);

  // Grab talent objects for everyone
  // const preselectedLookbook = params?.Lookbook_id && getLookbookById(user, params?.Lookbook_id);
  const [selectedTalent, setSelectedTalent] = useState([]);
  const [isFetchingTalent, setIsFetchingTalent] = useState(true);
  const getFullTalentObjects = async () => {
    const preselectedList = params.BrandList_id && getBrandListById(user, params.BrandList_id);
    const userIdsNeeded = _.uniq(_.concat(_.map(preselectedList?.users, 'User_id'), _.map(params?.talent, 'id')));
    const usersFromTalentObject = talent.filter(t => userIdsNeeded.includes(t.id));
    const userIdsFoundSet = new Set(usersFromTalentObject.map(u => u.id));

    let finalUsers = [...usersFromTalentObject];
    if (finalUsers.length !== userIdsNeeded.length) {
      // If we don't have all the users we need, fetch them
      const missingIds = userIdsNeeded.filter(id => !userIdsFoundSet.has(id));

      if (missingIds.length) {
        try {
          setIsFetchingTalent(true);
          const resp = await getTalentAPI(getBrandId(user), { User_ids: missingIds.join(','), getDetailedResults: true });
          finalUsers = [...finalUsers, ...resp.talent];
        } catch (error) {
          window.ALERT.warn('Had an issue auto-selecting those users, please try again.');
        }
      }
    }

    setIsFetchingTalent(false);
    setSelectedTalent(_.orderBy(finalUsers, 'score', 'desc'));
  };
  useEffect(() => {
    getFullTalentObjects();
  }, []);

  /*
    Establish which talent we are taking bulk actions on and allow changing
    of that list.
  */

  const removeTalentById = id => setSelectedTalent(selectedTalent.filter(t => t.id !== id));
  const removeTalentByOffPlatformEmail = email => setSelectedTalent(selectedTalent.filter(t => t.email !== email));
  const removeAllTalent = () => {
    setSelectedTalent([]);
    updateStatusState({});
  };
  const removeAllTalentWithChats = () => {
    const talentWithoutChats = selectedTalent.filter(t => !t.has_chatted);
    const numTalentRemoved = selectedTalent.length - talentWithoutChats.length;
    setSelectedTalent(talentWithoutChats);
    updateStatusState({});
    window.ALERT.success(numTalentRemoved ? `Removed ${numTalentRemoved} talent with existing chats.` : `No talent with existing chats to remove.`);
  };
  const removeAllTalentWithRequests = () => {
    const talentWithoutRequests = selectedTalent.filter(t => !t.has_request);
    const numTalentRemoved = selectedTalent.length - talentWithoutRequests.length;
    setSelectedTalent(talentWithoutRequests);
    updateStatusState({});
    window.ALERT.success(
      numTalentRemoved ? `Removed ${numTalentRemoved} talent with existing requests.` : `No talent with existing requests to remove.`
    );
  };

  const selectNewTalent = talent => setSelectedTalent([talent, ...selectedTalent]);
  const selectListToAdd = list => {
    const listUserIds = new Set(list.users.map(listUser => listUser.User_id));
    const selectedTalentIds = new Set(selectedTalent.map(user => user.id));
    const talentToAdd = talent.filter(t => listUserIds.has(t.id) && !selectedTalentIds.has(t.id));
    setSelectedTalent([...talentToAdd, ...selectedTalent]);
  };
  const addNewTalentOffPlatform = allTalentToAdd => {
    const filteredTalentToAdd = allTalentToAdd.filter(t => !selectedTalent.find(s => s.email === t.email));
    setSelectedTalent([...filteredTalentToAdd, ...selectedTalent]);
  };

  /*
    Store UI indicators of which actions are taking place.
  */
  const talentStatusRef = useRef({});
  const [talentStatuses, setTalentStatuses] = useState({});
  const [isProcessingSend, setIsProcessingSend] = useState(false);
  const updateStatusForTalent = (talent, newStatus) => {
    let newStatuses = { ...talentStatusRef.current };
    if (newStatus) newStatuses[talent.id || talent.email] = newStatus;
    else delete newStatuses[talent.id || talent.email];
    updateStatusState(newStatuses);
  };
  const updateStatusState = newStatuses => {
    setTalentStatuses(newStatuses);
    talentStatusRef.current = newStatuses; // Due to issue in scoping, somewhat hacky but works well
  };
  const batchActions = async (talentToAction, actionFn) => {
    const batches = _.chunk(talentToAction, 3); // Fire off only X at a time
    if (isProcessingSend) return;

    window.onbeforeunload = () => 'Actions are still in progress, are you sure you want to leave?';
    setIsProcessingSend(true);
    for (let batch of batches) {
      await Promise.all(batch.map(actionFn));
    }
    window.onbeforeunload = null;
    setIsProcessingSend(false);
    await props.syncTalent();
  };

  /*
    Establish which type of outreach we are doing and which talent are "disabled" meaning they cannot
    be reached out to.
  */
  const brand = getBrand(user);
  const unlockedRates = isSubscribedToFeature(user, 'CUSTOM_RATES');
  const isSMSAffiliatePartner = isSMSAffiliatePartnerBrand(user);
  const unlockedBulkMessaging = isSubscribedToFeature(user, 'BULK_MESSAGING');

  // What type of outreach can a brand offer
  const canOfferRate = unlockedRates && isSMSAffiliatePartner;
  const outreachOptions = [
    {
      value: {
        type: 'general',
        showRate: false,
        getDisabledMessage: talent => null,
        getErrorMessage: talent => 'There was an error, please try again.',
        getOOSMessage: talent => 'No bulk messages remaining.',
        actionLabel: count =>
          count <= 1 ? `Send${isProcessingSend ? 'ing' : ''} Message` : `Send${isProcessingSend ? 'ing' : ''} ${count} Messages`,
        clickSend: async talentToAction => {
          const talentToSend = talentToAction;

          if (blockOnRequiringSubscription(user, 'BULK_MESSAGING')) return null;

          await batchActions(talentToSend, async (talent, idx) => {
            try {
              const chatMessageData = {
                User_id: talent.id,
                Brand_id: brand.id,
                isUserMessage: false,
                message: replaceTemplateVariables(curMessage, talent, brand),
                isBulk: true
              };

              curMessage && (await sendChatMessage(chatMessageData));
              for (const file of filesToUpload) {
                await sendChatMessage({
                  ...chatMessageData,
                  message: file
                });
              }

              updateStatusForTalent(talent, 'success');
            } catch (error) {
              updateStatusForTalent(talent, 'error');
            }
          });
        },
        warningMessage: !unlockedBulkMessaging ? `You are not currently subscribed to the communications module and cannot send bulk messages.` : '',
        disabledMessage: false
      },
      label: 'Send General Message'
    },
    {
      value: {
        type: 'rates',
        showRate: true,
        getDisabledMessage: talent => {
          const canOfferRateToUser = isPromoterForOutreachPurposes(talent);
          const canOfferRateNoMatterWhat =
            isAdminControlMode(ui) || isSubscribedToFeature(user, canOfferRateToUser ? 'CUSTOM_RATES' : 'UNLIMITED_CHAT');
          const canOfferRate = canOfferRateToUser || canOfferRateNoMatterWhat;
          if (!canOfferRate) return 'Not a promoter';
          return null;
        },
        getErrorMessage: talent => 'There was an error, please try again.',
        getOOSMessage: talent => null,
        numRemaining: null,
        actionLabel: count => (count <= 1 ? `Updat${isProcessingSend ? 'ing' : 'e'} Rate` : `Updat${isProcessingSend ? 'ing' : 'e'} ${count} Rates`),
        clickSend: talentToAction => {
          const rate = parseFloat(curRate.replace(/[^0-9.]/g, ''));
          if (!rate) return cogoToast.warn('Please enter a valid rate');
          batchActions(talentToAction, async talent => {
            const resp = await props.setCustomCommissionRate(
              talent.id,
              rate,
              null,
              curMessage ? replaceTemplateVariables(curMessage, talent, brand) : null
            );
            resp.customRates ? updateStatusForTalent(talent, 'success') : updateStatusForTalent(talent, 'error');
          });
        },
        warningMessage: null,
        disabledMessage: canOfferRate ? null : 'Custom Rates are not enabled for your brand'
      },
      label: 'Offer Custom Rates',
      sublabel: !unlockedRates
        ? `You must subscribe to the custom rates feature to use this.`
        : !isSMSAffiliatePartner
        ? `You must be on the direct ShopMy affiliate network to use this.`
        : ''
    },
    {
      value: {},
      type: 'gifting',
      label: 'Send Gifting Request',
      onSelectOverride: () =>
        props.openRequestModal({
          params: {
            preselectedUsers: selectedTalent.length
              ? formatUsersForBulkRequests(selectedTalent)
              : window.ALERT.warn('Please select talent to send gifting requests to.')
          }
        })
    }
  ];
  const [outreachTypeEnum, setOutreachTypeEnum] = useState(params.outreachType || outreachOptions[0].value?.type);
  const outreachType = outreachOptions.find(o => o.value.type === outreachTypeEnum)?.value;
  const validSelectedTalent = selectedTalent.filter(t => !outreachType.getDisabledMessage(t) && talentStatuses[t.id || t.email] !== 'success');

  React.useEffect(() => {
    // Only allow files in general mode
    if (outreachTypeEnum !== 'general') setFilesToUpload([]);
  }, [outreachTypeEnum]);

  if (!outreachType) return null;

  return (
    <Modal visible close={close} showClose innerClassName='bulk-talent-modal' contentClassName='bulk-talent-inner-modal'>
      <>
        <div className='sidebar-container'>
          <BulkTalentModalSidebar
            user={user}
            analytics={props.analytics}
            allTalent={talent}
            selectedTalent={selectedTalent}
            selectNewTalent={selectNewTalent}
            selectListToAdd={selectListToAdd}
            removeTalentById={removeTalentById}
            removeTalentByOffPlatformEmail={removeTalentByOffPlatformEmail}
            removeAllTalent={removeAllTalent}
            removeAllTalentWithChats={removeAllTalentWithChats}
            removeAllTalentWithRequests={removeAllTalentWithRequests}
            outreachType={outreachType}
            isFetchingTalent={isFetchingTalent}
            talentStatuses={talentStatuses}
            openArtistModal={props.openArtistModal}
            openChatOverlay={props.openChatOverlay}
            addNewTalentOffPlatform={addNewTalentOffPlatform}
            isProcessingSend={isProcessingSend}
          />
        </div>
        <div className='main-container'>
          <div className='main-body'>
            <BulkTalentModalBody
              user={user}
              curMessage={curMessage}
              setCurMessage={setCurMessage}
              curRate={curRate}
              setCurRate={setCurRate}
              updateBrandSettings={props.updateBrandSettings}
              outreachType={outreachType}
              filesToUpload={filesToUpload}
              setFilesToUpload={setFilesToUpload}
            />
          </div>
          <div className='main-footer'>
            <BulkTalentModalFooter
              curMessage={curMessage}
              curRate={curRate}
              outreachOptions={outreachOptions}
              outreachType={outreachType}
              setOutreachTypeEnum={setOutreachTypeEnum}
              validSelectedTalent={validSelectedTalent}
              brandRemaining={brandRemaining}
              fetchingRemaining={fetchingRemaining}
              filesToUpload={filesToUpload}
              setFilesToUpload={setFilesToUpload}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

BulkTalentModal.propTypes = {
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  updateBrandSettings: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  syncTalent: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, talent, analytics } = state;
  return { user, ui, talent, analytics };
};

export default connect(mapStateToProps, {
  closeBulkTalentModal,
  updateBrandSettings,
  createSamplesRequest,
  setCustomCommissionRate,
  openArtistModal,
  openChatOverlay,
  openRequestModal,
  syncTalent
})(BulkTalentModal);
